import styled from 'styled-components'

export const Container = styled.div`
    width: 1116px;
    height: 350px;
    margin: 0 auto;
    margin-top: 34px;

    display: flex;
    justify-content: space-between;
`;

export const PlanCard = styled.div`
    width: 350px;
    height: 350px;

    border: 1px solid ${props => props.borderShow ? props.theme.colors.main : props.theme.colors.borderGrey};
    border-radius: 3px;
    position: relative;
`;

export const CardContent = styled.div`
    width: auto;
    margin: 20px 33px;
`;

export const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
        color: ${props => props.current ? props.theme.colors.main : 'black'};
        font-weight: 600;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100px;
        height: 22px;
        background-color: ${props => props.theme.colors.sandle};
        
        border-radius: 3px;

        p {
            font-size: 10px;
            color: ${props => props.theme.colors.white};
            font-weight: 600;
        }
    }
`;

export const Features = styled.ul`
    list-style: none;
    margin-top: 30px;

    li {
        margin-top: 18px;   
        color:  ${props => props.theme.colors.darkGrey};

        display: flex;
        align-items: center;

        span {
            color:  ${props => props.theme.colors.black};
            font-weight: 400;
        }

        img {
            width: 20px;
            margin-right: 19px;
        }
    }
`;

export const Price = styled.div`
    margin-top: 29px;

    h2 {
        font-weight: 600;

        span {
            font-size: 10px;
            color: ${props => props.theme.colors.darkGrey};
            margin-left: 6px;
        }
    }
`;

export const Footer = styled.div`
    position: absolute;
    bottom: 29px;
`;

export const PurchaseButton = styled.button`
    width: 283px;
    height: 35px;
    border: none;
    background-color: ${props => props.theme.colors.main};
    border-radius: 3px;
    color: ${props => props.theme.colors.white};
    font-weight: 600;
`;

export const EnterCredits = styled.form`
`;

export const InputWrapper = styled.div`
    display: flex;  
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 21px;

    label {
        font-size: 12px;
    }
`;

export const CreditInput = styled.input`
    width: 156px;
    height: 35px;
    border: none;
    outline: none;
    padding: 0 14px;
    /* background-color: ${props => props.theme.colors.inputBg}; */
    border-radius: 3px;
    border: 1px solid ${props => props.theme.colors.borderGrey};

    font-size: 12px;

    :focus {
        border: 1px solid ${props => props.theme.colors.main};
    }

    ::placeholder {
        color: #BDBDBD;
    }
`;