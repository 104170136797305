import styled from "styled-components"

export const Container = styled.div`
    width: 100%;
    height: 75px;
    background-color: ${props => props.theme.colors.main};
`;

export const Content = styled.div`
    width: calc(100% - 200px);
    height: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;

`;

export const LogoWrapper = styled.div`
    width: 200px;
    height: 100%;

    display: flex;
    align-items: center;

    img {
        width: 150px;
    }
`;

export const UserInfo = styled.div`
    width: 400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;

    p.credits {
        color: white;
    }
`;

export const NavigateButton = styled.a`
    width: 200px;
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    border-radius: 3px;
    cursor: pointer;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.left {
        width: 6px;
        margin-left: 10px;
    }

    img.right {
        width: 6px;
        margin-right: 10px;
    }
`;

export const Avatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: "white";
    background-image: url(${props => props.image});
    background-size: contain;
    cursor: pointer;
`;

export const DropDown = styled.div`
    width: 256px;
    height: 160px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 65px;
    border-radius: 3px;
    left: 150px;

    display: ${props => props.open ? 'block': 'none'}
`;

export const Head = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;

    button {
        width: 70px;
        height: 100%;
        
        border: none;
        font-size: 12px;
        font-weight: 400;
        border-radius: 0 0 0 3px;
        border: 1px solid ${props => props.theme.colors.buttonGrey};
        background-color: ${props => props.theme.colors.white};

        :hover {
            background-color: ${props => props.theme.colors.backgroundGrey};
            cursor: pointer;
        }
    }
`;

export const UserContent = styled.div`
    width: 100%;
    height: 100px;

    display: flex;
    align-items: center;
`;

export const PictureWrap = styled.div`
    width: 35%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InfoWrap = styled.div`
    display: flex;
    flex-direction: column;

    h4 {
        font-weight: 500;
    }

    h6 {
        font-weight: 400;
        color: ${props => props.theme.colors.mediumGrey};
        margin-top: 3px;
    }

    h5 {
        font-weight: 400;
        margin-top: 13px;

        span {
            color: ${props => props.theme.colors.mediumGrey};
        }
    }
`;

export const Picture = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: url(${props => props.image});
    background-size: contain;
`;

export const LogoInfo = styled.div`
    display: flex;
    align-items: center;
`;

export const DownloadButton = styled(NavigateButton)`
    width: 220px;
    background: rgb(0 0 0 / 38%);
    text-decoration: none;

    img {
        width: 18px;
        margin-right: 10px;
        margin-left: 0px;
    }
`;