const theme = {
    colors: {
        main: '#2668B2',
        white: 'white',
        borderGrey: '#DFE2E5',
        lightBlue: '#E8F3FE',
        buttonBorder: '#AEB6BE',
        sandle: '#F6B43B',
        darkGrey: '#999999',
        black: 'black',
        inputBg: '#F7F7F7',
        tableHeading: '#F7F8FA',
        buttonGrey: '#D9D9D9',
        mediumGrey: '#7C7C7C',
        backgroundGrey: '#EEEEEE'
    }
}
export default theme
