import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { normalize } from 'styled-normalize'


export const GlobalStyle = createGlobalStyle`
  ${normalize}

  :root {
    --fs-btn: 16px;

    --fs-h2: 20px;
    --fs-h3: 18px;
    --fs-h4: 16px;
    --fs-h5: 12px;

    --fs-h6: 10px;

    --fs-li: 14px;
  }
 
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;  
    padding: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  button {
    font-size: var(--fs-btn)
  }

  body{
    background-color: #F7F8FA;
  }

  h2 {
    font-size: var(--fs-h2);
  }

  h3 {
    font-size: var(--fs-h3)
  }

  h4 {
    font-size: var(--fs-h4)
  }

  h5 {
    font-size: var(--fs-h5)
  }

  h6 {
    font-size: var(--fs-h6)
  }

  li {
    font-size: var(--fs-li)
  }
`;

const SpinAnime =  keyframes`
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  margin: auto;
  border: 2px dotted #dbf2ff; 
  width: 22px;
  height: 22px;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border-right: 2px solid #018df7;
  text-align:center;
  animation-name: ${SpinAnime};
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
`;

const LineAnim =  keyframes`
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
`;

export const LineLoader = styled.div`
  width: 60%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 1rem auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  ::before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: #2668B2;
    animation-name: ${LineAnim};
    animation-duration: 900ms;
    animation-iteration-count: infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
`;

