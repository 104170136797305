import React, { useState } from "react"
import styled from 'styled-components'

const Anchor = styled.a`
    color: #1a73e8;
    text-decoration: none;
    cursor: pointer;
`;

const RevealPhone = ({ data }) => {
    const [revealed, setRevealed] = useState(false)

    if(revealed) {
        return data && <p>{data}</p> 
    }

    return data ? <Anchor onClick={() => { setRevealed(true) }}>Reveal</Anchor> : <p>-</p>
}

export const COLUMNS = [
    {
        Header: 'Username',
        accessor: 'username',
        Cell: ({ value, row }) => {
            return <Anchor href={row.original.profileUrl} target="_blank">{value}</Anchor>
        }
    },
    {
        Header: 'Company',
        accessor: 'company',
        disableGlobalFilter: true
    },
    {
        Header: 'Role',
        accessor: 'role',
        disableGlobalFilter: true
    },
    {
        Header: 'Email',
        accessor: 'email',
        disableGlobalFilter: true
    },
    {
        Header: 'Phone',
        accessor: 'phone',
        disableGlobalFilter: true,
        Cell: ({ value, row }) => {
            return <RevealPhone data={row.original.phone} />
        }
    },
    {
        Header: 'Location',
        accessor: 'location',
        disableGlobalFilter: true
    }
]