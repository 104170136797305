import styled from 'styled-components'

export const Container = styled.div`
    position: absolute;
    bottom: 19px;
`;


export const PurchaseButton = styled.button`
    width: 283px;
    height: 35px;
    border: none;
    background-color: ${props => props.theme.colors.main};
    border-radius: 3px;
    color: ${props => props.theme.colors.white};
    font-weight: 600;
    cursor: pointer;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const EnterCredits = styled.form`
    display: flex;
    flex-direction: column;
    /* margin-top: 21px; */

    p {
        font-size: 10px;
        align-self: flex-end;
        color: red;
    }
`;

export const InputWrapper = styled.div`
    display: flex;  
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    label {
        font-size: 12px;
    }
`;

export const CreditInput = styled.input`
    width: 156px;
    height: 35px;
    border: none;
    outline: none;
    padding: 0 14px;
    /* background-color: ${props => props.theme.colors.inputBg}; */
    border-radius: 3px;
    border: 1px solid ${props => props.red ? 'red' : props.theme.colors.borderGrey};

    font-size: 12px;

    :focus {
        border: 1px solid ${props => props.red ? 'red' : props.theme.colors.main};
    }

    ::placeholder {
        color: #BDBDBD;
    }
`;