import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container,Box,OutCircle,InCircle,Message,DetailsBox,Back } from './cancel.styled';
import exclaim from './images/exclaim.svg'

const PaymentCancel = () => {
    const navigate = useNavigate()

    return (
      <Container>
        <Box>
        <OutCircle>
      <InCircle><img src={exclaim} alt=''/></InCircle>
    </OutCircle>
    <Message>Payment Failed</Message>
    <DetailsBox>
      <p><span>Don’t worry your money is safe!</span> If money was debited from
      your account, it will be refunded automatically in
      5-7 working days.
      </p>
    </DetailsBox>
    <Back onClick={() => { navigate('/dashboard') }}>
      <p>Back to Home</p>
    </Back>
        </Box>
        </Container>
      );
};

export default PaymentCancel;