import React, { useEffect,useState } from 'react'
import Slider from "react-slick"
import { useLocation, useNavigate } from 'react-router-dom'

import { Container, GoogleBtn, GoogleSection, LeftSection } from './index.styled'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import SliderCnt from './components/slider'
import { useAuth } from '../../../context/auth'

import _static from '../../../config/static'

const { dashboardPage, googleClientId } = _static

const Login = () => {
  
  const [data, setData] = useState([
      {
          url: "/photo.png",
          message:"LinkLeadSpy is one of the most accurate tools for getting verified email addresses and phone numbers of the targeted audience for generating quality leads to increase sales and business ROI. Identifying targeted profiles and obtaining their correct email addresses is now super easy with LinkLeadSpy."
      },{
          url:"/remo.png",
          message:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation   ullamco laboris nisi ut aliquip ex ea commodo consequat."
      },{
          url:"/dude.png",
          message:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation   ullamco laboris nisi ut aliquip ex ea commodo consequat."
      }
    ])

    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()


    const [nav1, setNav1] = useState()
    const [nav2, setNav2] = useState()

    const [scriptLoaded, setScriptLoaded] = useState(false);
   
    const handleLogin = (response) => {
        auth.handleLoginCallback(response)
        navigate(`/${dashboardPage}`, { replace: true })
    }

    useEffect(() => {
      if (scriptLoaded) return undefined;
    
      const initializeGoogle = () => {
        if (!window.google || scriptLoaded) return;
    
        setScriptLoaded(true)

        window.google.accounts.id.initialize({
          client_id: googleClientId,
          callback: handleLogin,
          auto_prompt: true,
          auto_select: true
        })
        window.google && window.google.accounts.id.renderButton(
          document.getElementById("signInDiv"),
          { 
            theme: "filled_blue", 
            size: "large",
            text: "continue_with",
            shape: "circle",
            logo_alignment: "left",
            width: 500,
            height: 100
          }
        )
        // window.google && window.google.accounts.id.prompt(notification => {
        //   console.log('on prompt', notification)
        // })
      }
    
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = initializeGoogle;
      script.async = true;
      script.id = "google-client-script";

      let body = document.querySelector("body")
      body && body.appendChild(script)
    
      return () => {
        window.google && window.google.accounts.id.cancel();
        let scriptTag = document.getElementById("google-client-script")
        scriptTag && scriptTag.remove();
      };
    }, [scriptLoaded]);

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
        <Container>
          <LeftSection>
            <div id="leftin">
              <div id="leftcontent">
                <div id='logos'>
                    <img src="logowhite.png" />
                    <img src="/logotext.png" />
                </div>
              
                <Slider
                    {...settings}
                    className="mainSlider"
                    asNavFor={nav1}
                    ref={slider1 => setNav2(slider1)}
                >
                    {data.map((item, idx)=>{
                      return (
                        <div key={idx}>
                            <SliderCnt data={item} idx={idx} />
                        </div>
                    )
                })}
                </Slider>
              </div>
            </div>
          </LeftSection>
        
            <GoogleSection >
              <div id="indiv">
                <img src="logoblue.png" />
                <h1>Login to your Account</h1>
                <p>See what is going on with your business</p>
                <GoogleBtn className={"g_id_signin"}><div  id="signInDiv"></div></GoogleBtn>
              </div>
            </GoogleSection>
        </Container>
      )
}

export default Login