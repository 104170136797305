export const appPlans = [
    {
        name: 'Starter Plan',
        code: 'starter',
        features: {credits: '50', validEmails: '50', users: '1'},
        current: true
    },
    {
        name: 'Basic Plan',
        code: 'basic',
        features: {credits: '500', validEmails: '500', users: '1'},
        creditLimit: 500,
        current: false
    },
    {
        name: 'Custom Plan',
        code: 'custom',
        features: {credits: 'Unlimited', validEmails: 'Unlimited', users: '1'},
        current: false
    }
]
