import React from "react"
import { useLocation, useNavigate, Link } from "react-router-dom"


import { Container, Content, LogoWrapper, UserInfo, NavigateButton, Avatar, DropDown, Head, UserContent, PictureWrap, InfoWrap, Picture, LogoInfo, DownloadButton } from './header.styled'

import { useAuth } from '../../../context/auth'
import _static from "../../../config/static"

const { planPage, dashboardPage } = _static

const Header = ({open, setOpen}) => {
    const auth = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    const renderButtonText = () => {
        if(location.pathname.includes(planPage)) {
            return <div>
                <img className="right" src="/arrowprev.png"/>
                <p>{"Back to Dashboard"}</p>
            </div>
        } else if (location.pathname.includes(dashboardPage)) {
            return <div>
                <p>{"Purchase credits"}</p>
                <img className="left" src="/right-arrow.png"/>
            </div>
            
        }
    }

    const handleClick = () => {
        if(location.pathname.includes(planPage)) {
            navigate(`/${dashboardPage}`)
        } else if (location.pathname.includes(dashboardPage)) {
            navigate(`/${planPage}`)
        }
    }

    const handleLogout = () => {
        auth.logout()
    }

    return <Container onClick={() => {setOpen(false)}}>
        <Content>
            <LogoInfo>
                <Link to="/dashboard">
                    <LogoWrapper>
                        <img src="/lls-logo.png" />
                    </LogoWrapper>
                </Link>

                <DownloadButton href="https://chrome.google.com/webstore/detail/hbbhgllhlfcicehdfhmheodjfmhnpcla" target="_blank"><img src="/chrome.png"/>Download extension</DownloadButton>
            </LogoInfo>

            <UserInfo>
                <p className="credits">Credits left: {auth.user.credits}</p>
                <NavigateButton onClick={handleClick}>{renderButtonText()}</NavigateButton>
                <Avatar image={auth.user.profileImage} onMouseEnter={() => {setOpen(true)}}/>

                <DropDown open={open} onMouseLeave={() => {setOpen(false)}}>
                    <Head>
                        <button onClick={handleLogout}>Sign out</button>
                    </Head>

                    <UserContent>   
                        <PictureWrap>
                            <Picture image={auth.user.profileImage}></Picture>
                        </PictureWrap>

                        <InfoWrap>
                            <h4>{auth.user.username}</h4>
                            <h6>{auth.user.email}</h6>

                            <h5><span>Credits: </span>{auth.user.credits}</h5>
                        </InfoWrap>
                    </UserContent>
                </DropDown>
            </UserInfo>
        </Content>
    </Container>
}

export default Header