import React, { useState, createContext, useContext, useEffect } from 'react'
import _static from '../config/static'


const AuthContext = createContext(null)
const { baseUrl } = _static

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleLoginCallback = async (response) => {
        const idToken = response.credential

        try {
          const response = await fetch(`${baseUrl}/leadspyder/api/auth/login`, {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`  
            },
            credentials: 'include'
          })
          const result = await response.json()

          setUser(result)
        } catch(err) {
          console.log(err)
        }
    }

    const logout = async () => {
        try {
            await fetch(`${baseUrl}/leadspyder/api/auth/logout`, {
              method: "POST",
              credentials: 'include'
            })
  
            setUser(null)
          } catch(err) {
            console.log(err)
          }
    }

    const getCurrentUser = async () => {
      try {
        setLoading(true)

        const response = await fetch(`${baseUrl}/leadspyder/api/auth/users/current`, {
          method: "GET",
          credentials: 'include'
        })

        const result = await response.json()

        
        if(response.ok){
          setUser(result)          
        } else {
          setUser(null)
        }
        setLoading(false)
      } catch(err) {
        setUser(null)
        setLoading(false)
      }
    }

    return <AuthContext.Provider value={{ user, handleLoginCallback, logout, getCurrentUser, loading }}>
        {children}
    </AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}