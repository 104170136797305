import React, { useEffect } from 'react'

import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuth } from "../../context/auth"

import GlobalLoader from './GlobalLoader'

const ProtectedLayout = () => {
    const { loading, user, getCurrentUser } = useAuth()
    const location = useLocation()

    if(loading) return <GlobalLoader />

    return user ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace/>
}

export default ProtectedLayout

