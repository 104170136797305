import React, { forwardRef, useEffect, useRef } from "react"
import styled from 'styled-components'

import './checkbox.css'

const Check = styled.input`
    height: 20px;
`;

export const CheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return <>
        <Check type="checkbox" ref={resolvedRef} {...rest} />
    </>
})