import React, { useEffect, useState } from "react"

import { Container, PlanCard, CardContent, Heading, Features, PurchaseButton, EnterCredits, CreditInput, InputWrapper } from './plans.styled'

import Price from "./price"
import Footer from "./footer"

import { useAuth } from "../../../../context/auth"
import { appPlans } from "./data"

const Plans = () => {
    const auth = useAuth()

    return <Container>
            {appPlans.map((plan) => {
                return <PlanCard borderShow={plan.code === auth.user.currentPlan}>
                    <CardContent>
                        <Heading current={plan.current}>
                            <h3>{plan.name}</h3>

                            {plan.code === auth.user.currentPlan && <div><p>CURRENT PLAN</p></div>}
                        </Heading>

                        <Features>
                            <li><img src="/fill-tick.png"/><span>{plan.features.credits}</span>&nbsp;credits</li>
                            <li><img src="/fill-tick.png"/><span>{plan.features.validEmails}</span>&nbsp;valid emails</li>
                            <li><img src="/fill-tick.png"/><span>{plan.features.users}</span>&nbsp;user</li>
                        </Features>

                        <Price planCode={plan.code}/>

                        <Footer planCode={plan.code} />
                    </CardContent>
                </PlanCard>
            })}
    </Container>
}

export default Plans