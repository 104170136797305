import React, { useMemo, useEffect } from "react"
import styled from "styled-components"
import { useTable, useSortBy, useGlobalFilter, usePagination, useRowSelect } from 'react-table'

import { COLUMNS } from "./columns"
import GlobalFilter from "./GlobalFilter"
import { CheckBox } from "./Checkbox"

import { useCustomTable } from "../../context/tableContext"

import './table.css'

const FilterRow = styled.div`
`;

const PaginationWrapper = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Prev = styled.button`
    width: 80px;
    height: 30px;
    background: none;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    font-size: 0.9rem;
    margin: 0 0.5rem;
    cursor: pointer;

    :hover {
        background-color: #ededed;
    }
`;

const Next = styled(Prev)`

`;

const Table = () => {
    const { userLeads, getUserLeads } = useCustomTable()

    useEffect(() => {
        getUserLeads()
    }, [])


    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => userLeads, [userLeads])

    const tableInstance = useTable({
        columns,
        data
    },  useGlobalFilter, useSortBy,  usePagination, useRowSelect,  (hooks) => {
        hooks.visibleColumns.push((columns) => {
            return [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <CheckBox {...getToggleAllRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (
                        <CheckBox {...row.getToggleRowSelectedProps()}/>
                    )
                },
                ...columns
            ]
        })
    })

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, state, setGlobalFilter, page, nextPage, previousPage, canNextPage, canPreviousPage, pageOptions, gotoPage, pageCount  } = tableInstance
    
    const { globalFilter, pageIndex } = state

    return <FilterRow>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} leads={userLeads}/>
        <table {...getTableProps()} className="dashboard">
            <thead>
                {
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? '↓': '↑') : ''}
                                        </span>
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>

            <tbody {...getTableBodyProps()}>
                {
                    page.map(row =>{
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {
                                    row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>   

        <PaginationWrapper>
                <span>
                    <p>Page {pageIndex + 1} of {pageOptions.length}</p>
                </span>
                <Prev onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Prev>
                <Prev onClick={() => previousPage()} disabled={!canPreviousPage}>Prev</Prev>
                <Next onClick={() => nextPage()} disabled={!canNextPage}>Next</Next>
                <Next onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Next>
        </PaginationWrapper>
    </FilterRow>
    
    
}

export default Table