import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 35px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Export = styled.div`
    width: 80px;
    height: 35px;

    display: flex; 
    align-items: center;
    justify-content: flex-end;

    a {
        width: 100%;
        height: 100%;
        border: none;
        background: white;
        border-radius: 4px;
        /* box-shadow: 0 2px 4px 1px rgb(50 50 93 / 10%); */
        border: 1px solid #DFE2E5;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        color: #747474;
        font-size: 0.9rem;

        img {
            margin-left: 10px;
            width: 18px;
        }
    }
`;

export const HeadingRow = styled.div`
    width: 100%;
    height: 80px;

    display: flex;

    h2 {
        font-weight: 500;
        font-size: 1.4rem;
    }
`;

export const Left = styled.div`
    width: 50%;
    height: 100%;

    display: flex;
    align-items: center;
`;

export const Right = styled.div`
    width: 50%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;


    input.search {
        outline: none;
        font-size: 0.9rem;
        background: none;
        border: none;

        width: 70%;
    }
`;

export const InputWrap = styled.div`
    width: 248px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #DFE2E5;
    padding: 0 0.5rem;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

