import React, {useEffect, useState} from 'react'
import { useForm } from "react-hook-form"
import {  useToasts } from 'react-toast-notifications'
import axios from 'axios'
import {useStripe} from '@stripe/react-stripe-js'

import { Container, PurchaseButton, InputWrapper, EnterCredits, CreditInput } from './footer.styled'
import { Spinner } from '../../../../globalStyles'

import { usePlan } from '../context/plan'
import { useAuth } from '../../../../context/auth'

import _static from '../../../../config/static'

const { baseUrl, basicPlanCredits } = _static

const Footer = ({ planCode }) => {
    const [currentMin, setCurrentMin] = useState()
    const [loader, setLoader] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm()
    const { currentCurrency, customQuantity, setCustomQuantity, inrMin, usdMin } = usePlan()

    const auth = useAuth()

    const { addToast } = useToasts()
    const stripe = useStripe()

    const createCheckoutSession = async (selectedPlan) => {
        let quantity;

        if(selectedPlan === "basic"){
            quantity = basicPlanCredits
        } else if(selectedPlan === "custom") {
            quantity = customQuantity
        }

        const body = {
            currency: currentCurrency,
            quantity,
            email: auth.user.email,
            plan: selectedPlan
        }   
    
        const headers = {
            "Content-Type": 'application/json'
        }
    
        try {
            setLoader(true)
            const response = await axios(`${baseUrl}/leadspyder/api/payment/create-checkout-session`, {
                method: 'POST',
                headers,
                withCredentials: true,
                data: JSON.stringify(body)
            })

            stripe.redirectToCheckout({ sessionId: response.data.id })
        } catch(err) {
            console.log('err', err)
            setLoader(false)
        }
    }

    const onBasicSubmit = () => {
        createCheckoutSession("basic") 
    }

    const onCustomSubmit = () => {
        createCheckoutSession("custom")               
    }

    useEffect(() => {
        if(currentCurrency === 'inr') {
            setCustomQuantity(inrMin)
            setCurrentMin(inrMin)
        }
        else {
            setCustomQuantity(usdMin)
            setCurrentMin(usdMin)
        }
    }, [currentCurrency])

    useEffect(() => { 
        console.log('errors', errors)
    }, [errors])

    if(planCode === 'starter') {
        return
    }

    if(planCode === 'basic') {
        return <Container>
             <PurchaseButton onClick={onBasicSubmit}>{loader ? <Spinner /> : 'Purchase'}</PurchaseButton>
        </Container>
    }

    return <Container>
        <EnterCredits onSubmit={handleSubmit(onCustomSubmit)}>
            {customQuantity < currentMin && <p>min {currentMin} credits</p>}
            <InputWrapper>
                <label>Number of Credits</label>
                <CreditInput 
                    {...register("quantity", { required: true })}
                    type="number"
                    red={customQuantity < currentMin}
                    autoFocus
                    min={currentMin}
                    value={customQuantity}
                    onBlur={() => {
                        if(currentCurrency === 'inr') {
                            if(customQuantity < inrMin) {
                                setCustomQuantity(inrMin)
                            }
                        }else {
                            if(customQuantity < usdMin) {
                                setCustomQuantity(usdMin)
                            }
                        }
                    }}
                    onChange={(e) => {
                        if(!e.target.value) {
                            if(currentCurrency === 'inr') {
                                setCustomQuantity(inrMin)
                            } else {
                                setCustomQuantity(usdMin)
                            }
                            return
                        }
                        setCustomQuantity(parseInt(e.target.value))
                    }}
                />
            </InputWrapper>
            <PurchaseButton>{loader ? <Spinner /> : 'Purchase'}</PurchaseButton>
        </EnterCredits>
    </Container>
}

export default Footer