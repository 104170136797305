import styled from "styled-components"

export const Container = styled.div`
    margin-top: 29px;

    h2 {
        font-weight: 600;

        span {
            font-size: 10px;
            color: ${props => props.theme.colors.darkGrey};
            margin-left: 6px;
        }
    }
`;