import React from 'react'
import { Carousel, SliderCont,Images } from './slider.styled'

const SlideOne = ({ data }) => {
  return (
    <SliderCont>
        <Carousel>
            <p>LinkLeadSpy is one of the most accurate tools for getting verified email addresses and phone numbers of the targeted audience for generating quality leads to increase sales and business ROI. Identifying targeted profiles and obtaining their correct email addresses is now super easy with LinkLeadSpy.</p>
        </Carousel>
    </SliderCont>
  )
}

const SlideTwo = ({ data }) => {
  return (
    <SliderCont>
        <Carousel>
            <h3>Key Features of LinkLead Spy </h3>

            <ul>
              <li>Best source for extracting and copying verified email addresses & contact information for targeted audience</li>
              <li>LinkLeadSpy enables you to search quality leads through verified email and help you build your own list of potential customers.</li>
              <li>Makes it super easy to acquire new B2B leads.</li>
              <li>LinkLead Spy eases out the process of identifying targeted profiles and getting their correct email addresses.</li>
            </ul>
        </Carousel>
    </SliderCont>
  )
}

const SlideThree = ({ data }) => {
  return (
    <SliderCont>
        <Carousel>
            <h3>How To Use LinkLeadSpy? </h3>

            <ul>
              <li>Install the LinkLeadSpy Chrome extension</li>
              <li>Visit any LinkedIn profile for which you need to get email contact info</li>
              <li>Click on the LinkLeadSpy extension icon to enable it</li>
              <li>In the last step, click on "Get Contact Info" to copy the email address and contact number </li>
            </ul>
        </Carousel>
    </SliderCont>
  )
}


const SliderCnt = ({data, idx}) => {
  if(idx === 0){
    return <SlideOne data={data}/>
  } else if(idx === 1) {
    return <SlideTwo data={data}/>
  }

  return <SlideThree data={data}/>
}

export default SliderCnt