import styled from 'styled-components'

export const Container = styled.div`
    position:relative;
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
`;

export const LeftSection = styled.div`
    width:58%;
    height:100vh;
    background:url("/final.png") no-repeat center;
    background-size:cover;
    position:relative;

    #leftin{
        display:flex;
        justify-content:center;
        width:100%;
        height:100vh;
        background-color:rgba(40, 103, 178, 0.87);
        
    
        #leftcontent{
            width:80%;
            padding:20% 0 30% 0;
            
            .mainSlider{
                width:100%;
            }
        
        }

    #logos{
        display:flex;
        align-items:center;

        img{
            margin:0 2% 4% 2%;
        }
    }
    }
`;
export const GoogleSection = styled.div`
    width:40%;
    display:flex;
    justify-content:center;

    h1{
        font-family: 'Nunito Sans', sans-serif;
        font-weight:700;
        color:#525252;
        font-size:36px;
        margin:2% 0;
    }

    #indiv{
        margin-top:45%;
    }
    p{
        font-family: 'Nunito Sans', sans-serif;
        color:#525252;
        font-weight:400;
        font-size:16px; 
    }
`;

export const GoogleBtn = styled.div`
    width: 700px;
    margin-top: 75px;

    iframe{
        width:100%;
    }
`;
