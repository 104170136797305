import styled from "styled-components"

export const Container = styled.div`
    min-height: 100vh;
`;

export const Content = styled.div`
    width: calc(100% - 200px);
    height: auto;
    margin: 0 auto;
    margin-top: 2rem;

    display: flex;
    align-items: flex-start;
`;

export const NavBar = styled.div`
    width: 200px;
    height: 500px;
`;

export const Render = styled.div`
    width: calc(100% - 265px);
    height: auto;
    background-color: white;

    padding: 1rem;
`;

export const NavItem = styled.div`
    width: 90%;
    height: 40px;
    background-color: #C3DFFD;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    border-radius: 4px;

    a {
        text-decoration: none;
        color: #2668B2;
        position: absolute;
        left: 14px;
    }
`;