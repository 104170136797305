import React from 'react'
import { CSVLink } from "react-csv"

import { HeadingRow, Left, Right, InputWrap, Export } from './GlobalFilter.styled'

const GlobalFilter = ({ filter, setFilter, leads }) => {
    return <HeadingRow>
        <Left>
          <h2>All Contacts</h2>
        </Left>
        <Right>
          <InputWrap>
            <input 
              className='search'
              type="text"
              value={filter || ''}
              placeholder="Search user"
              onChange={(e) => setFilter(e.target.value)}  
            />
            <img src='/search.png' />
          </InputWrap>

          <Export>
            <CSVLink 
              data={leads} 
              target="_blank"
              filename={"my-contacts.csv"}
            >
                Export
                {/* <img src="/download.png"/> */}
            </CSVLink>    
          </Export> 
        </Right>
    </HeadingRow>       

      
}

export default GlobalFilter