import React, {useState} from "react"

import { Container, Content, NavBar, Render, NavItem } from './index.styled'

import Header from "./header/header"
import { Link, useLocation } from "react-router-dom"

const Layout = ({ children }) => {
    const [open, setOpen] = useState(false)
    const location = useLocation()

    

    return <Container onClick={() => {setOpen(false)}}>
        <Header open={open} setOpen={setOpen}/>
        <Content>
            <NavBar>
                {!location.pathname.includes('plans') && <NavItem>
                    <Link to="/dashboard">Contacts</Link>
                </NavItem>}

                {/* <NavItem> */}
                    {/* <Link to="/dashboard">Upgrade</Link> */}
                {/* </NavItem> */}
            </NavBar>
            
            <Render>
                {children}
            </Render>
        </Content>
    </Container>
}

export default Layout