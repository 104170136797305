
import React, { useEffect, useState } from 'react'

import { Container } from './price.styled.js'

import { usePlan } from '../context/plan.js'

const Price = ({ planCode }) => {

    const { currentCurrency, customQuantity, inrMin, usdMin } = usePlan()
    const [calculatedPrice, setCalculatedPrice] = useState(0)

    useEffect(() => {
        if(currentCurrency === 'inr') {
            if(customQuantity < inrMin) return
            setCalculatedPrice((customQuantity * 1.5).toFixed(2).replace(/[.,]00$/, ""))
        } else {
            if(customQuantity < usdMin) return
            setCalculatedPrice((customQuantity / 75).toFixed(2).replace(/[.,]00$/, ""))
        }
    }, [customQuantity])

    if(planCode === 'starter') {
        return <Container>
            <h2>FREE</h2>
        </Container>    
    }

    if(planCode === 'basic') {
        return <Container>
            <h2>{currentCurrency === 'inr' ? '₹ 1000': '$ 10'}
                <span>{currentCurrency === 'inr' ? '(Only ₹2 /credit)': '($1 = 50 credits)'}</span>
            </h2>
        </Container>
    }

    return <Container>
        <h2>{currentCurrency === 'inr' ? `₹${calculatedPrice}`: `$${calculatedPrice}`}<span>{currentCurrency === 'inr' ? '(Only ₹1.5 /credit)': '($1 = 75 credits)'}</span></h2>
    </Container>
}

export default Price