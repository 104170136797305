import React, { useEffect } from 'react'

import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuth } from "../../context/auth"

import GlobalLoader from './GlobalLoader'

import _static from '../../config/static'

const { dashboardPage } = _static

const HomeLayout = () => {
    const { loading, user } = useAuth()
    const location = useLocation()


    if(loading) return <GlobalLoader />

    return user ? <Navigate to={`/${dashboardPage}`} state={{ from: location }} replace/> : <Outlet />
}

export default HomeLayout