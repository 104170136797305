import styled from 'styled-components'

export const SliderCont = styled.div`
  width:100%;
  height:100%;
  margin-top:5%;
  position:relative;
`;
export const Images=styled.div`
    position:absolute;
    width:100%;

    z-index:1;
    display:flex;
    flex-wrap:wrap;
    left:5%;
    top:-6%;

    #vector{
        width:100%;
        display:flex;
    }
    #image{
        background:url("/photo.png") no-repeat center;
        background-size:cover;
        position:absolute;
        z-index:99;
        width:20%;
        top:270%;
        left:74%;
    }
`;

export const Carousel = styled.div`
    width: 93%;
    background-color: white;
    margin-left: 1%;
    padding: 7%;
    margin-bottom: 3%;
    position: relative;
    border-radius: 13px;

    p{
        font-family: 'Nunito Sans', sans-serif;
        color:#525252;
        font-weight:600;
        font-size: 22px;
        font-style:italic;
        line-height: 1.8rem;
    }

    ul li {
        font-family: 'Nunito Sans', sans-serif;
        color:#525252;
        font-weight:600;
        font-size: 22px;
        font-style:italic;
        margin-top: 0.5rem;
        line-height: 1.8rem;
    }

    h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        font-family: 'Nunito Sans',sans-serif;
        font-weight: 500;
        text-decoration: underline;
        line-height: 1.8rem;
    }
`;