import React, { useState, createContext, useContext, useEffect } from 'react'

const PlanContext = createContext(null)

export const PlanProvider = ({ children }) => {
    const [currentCurrency, setCurrentCurrency] = useState('inr')
    const [customQuantity, setCustomQuantity] = useState(1000)

    const inrMin = 1000
    const usdMin = 1500

    return <PlanContext.Provider value={{ currentCurrency, setCurrentCurrency, customQuantity, setCustomQuantity, inrMin, usdMin }}>
        {children}
    </PlanContext.Provider>
}

export const usePlan = () => {
    return useContext(PlanContext)
}