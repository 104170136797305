import React from "react"
import styled from "styled-components"

import { LineLoader } from "../../globalStyles"

const Container = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    width: 300px;
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const GlobalLoader = () => {
    return <Container>
        <Box>
            <img src="logo.png" />
            <LineLoader />

        </Box>
    </Container>
}

export default GlobalLoader