import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { Container,Box,OutCircle,InCircle,Message, Details,DetailsBox,Back } from './success.styled';

import tick from './images/Vector.svg'
import left from './images/left.svg'

import _static from '../../../config/static'

const { baseUrl } = _static

const PaymentSuccess = () => {
    const navigate = useNavigate()
    const stripe = useStripe()

    const [searchParams] = useSearchParams()
    const csId = searchParams.get('id') 

    const [data, setData] = useState(null)

    const getCheckoutSession = async () => {
      try {
        if(!csId) {
          return
        }
        const headers = {
          "Content-Type": 'application/json'
        }

        const response = await axios(`${baseUrl}/leadspyder/api/payment/get-checkout-session/${csId}`, {
            method: 'GET',
            headers,
            withCredentials: true
        })

        setData(response.data)
      } catch(err) {
          console.log('err', err)
      }
    }

    useEffect(() => {
      getCheckoutSession()
    }, [])

    useEffect(() => {
      console.log('data', data)
    }, [data])

    return (
      <Container>
        {data ? <Box>
            <OutCircle>
              <InCircle><img src={tick} alt=''/></InCircle>
            </OutCircle>
            <Message>Payment Successfull</Message>
            <DetailsBox>
            <Details>
              <p>Payment type</p>
              <p>Card</p>
            </Details>
            <Details>
              <p>Name</p>
              <p>{data.name}</p>
            </Details>
            <Details>
              <p>Email</p>
              <p>{data.email}</p>
            </Details>
            <Details>
              <p>Amount paid</p>
              <p className='amount'>
                {data.currency === "inr" ? `₹${data.amount}`: `$${data.amount}`}
              </p>
            </Details>
            <Details>
              <p>Credits Purchased</p>
              <p>{data.credits}</p>
            </Details>
            </DetailsBox>
            <Back onClick={() => { navigate('/dashboard') }}>
              <img src={left} alt=""/>
              <p>Back to Home</p>
            </Back>
        </Box> : <Box>
          <h1>Oops! Something went wrong</h1>  
          <Back onClick={() => { navigate('/dashboard') }}>
              <img src={left} alt=""/>
              <p>Back to Home</p>
          </Back>
        </Box>}
      </Container>
      );
};

export default PaymentSuccess;