import React, { useEffect } from 'react'

import { Container } from './index.styled'

import Table from './components/table'

import { TableProvider } from './context/tableContext'

const Dashboard = () => {    
    return <Container>
        <TableProvider>
            <Table />
        </TableProvider>
    </Container>
}

export default Dashboard