import React, { useEffect } from 'react'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import PaymentSuccessPage from './pages/Message/success'
import PaymentCancelPage from './pages/Message/cancel'

import LoginPage from './pages/Login'
import Plans from './pages/Plans'

import Layout from './layout'

import ProtectedLayout from './components/ProtectedLayout'
import HomeLayout from './components/HomeLayout'

import { useAuth } from '../context/auth'
import Dashboard from './pages/Dashboard'


const Content = () => {
    const auth = useAuth()

    useEffect(() => {
      auth.getCurrentUser()
    }, [])

    return <Router>
        <Routes>
            <Route 
                path="payment-cancel"
                element={
                    <PaymentCancelPage  />
                }
            />

            <Route 
                path="payment-success"
                element={
                        <PaymentSuccessPage />
                }
            />
         

            {/* PUBLIC ROUTES */}
            <Route element={<HomeLayout />}>
                <Route 
                    path="/"
                    element={<LoginPage />}
                />
            </Route>
        
            
            {/* PRIVATE ROUTES */}

                <Route element={<ProtectedLayout />}>
                    <Route
                        path="plans"
                        element={
                            <Layout>
                                <Plans/>
                            </Layout>
                        }
                    />

                    <Route 
                        path="dashboard"
                        element={
                            <Layout>
                                <Dashboard />
                            </Layout>
                        }
                    />
                </Route>
        </Routes>
    </Router>
};

export default Content;