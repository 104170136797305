import React, { useState } from 'react'
import { ToastProvider } from 'react-toast-notifications'

import { Container, Head, CurrencySelection, Instruction, CurrencyButtons, INRBtn, USDBtn, KeyFeatures, Ruler } from './index.styled'

import Plans from './components/plans'

import { PlanProvider } from './context/plan'
import { usePlan } from './context/plan'

const CurrencyB = () => {
    const { currentCurrency, setCurrentCurrency } = usePlan()

    return <CurrencyButtons>
        <INRBtn currency={currentCurrency} onClick={()=>{ setCurrentCurrency('inr') }}>INR</INRBtn>
        <USDBtn currency={currentCurrency} onClick={()=>{ setCurrentCurrency('usd') }}>USD</USDBtn>
    </CurrencyButtons>
}

const PlansPage = () => {
    return <ToastProvider placement='bottom-right' autoDismiss autoDismissTimeout={3000}>
        <PlanProvider>
                <Container>
                        <Head>
                            <h2>Choose your plan</h2>
                        </Head>

                        <CurrencySelection>
                            <Instruction>
                                <h3>Before you choose a plan, please select your currency</h3>
                            </Instruction>

                            <CurrencyB />
                        </CurrencySelection>

                        <Plans />

                        <Ruler />

                        <KeyFeatures>
                            <thead>
                                <tr>
                                    <th>Key Features of LinkLeadSpy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img src="/fill-tick.png"/> Finds verified email addresses & contact numbers </td>
                                </tr>
                                <tr>
                                    <td><img src="/fill-tick.png"/> Extracts accurate info</td>
                                </tr>
                                <tr>
                                    <td><img src="/fill-tick.png"/> Easy to use interface</td>
                                </tr>
                                <tr>
                                    <td><img src="/fill-tick.png"/> Reliable & completely secure to use</td>
                                </tr>
                            </tbody>
                        </KeyFeatures>
                    </Container>
            </PlanProvider>
    </ToastProvider>
    
    
}

export default PlansPage