import styled from 'styled-components'

export const Container=styled.div`
    width:100%;
    height:100vh;
    font-family: 'Inter', sans-serif;
`
export const Box=styled.div`
    width:30%;
    padding:2% 2.5% ;
    margin:5% auto;
    background-color:white;
`
export const OutCircle = styled.div`
    width:80px;
    height:80px;
    margin:2% auto 1% auto;
    border-radius:50%;
    background-color:rgba(33, 196, 143, 0.2);
    display:flex;
    justify-content:center;
    align-items:center;
`
export const InCircle = styled.div`
    background-color:rgba(33, 196, 143, 1);
    border-radius:50%;
    width:66px;
    height:66px;
    display:flex;
    justify-content:center;
    align-items:center;
`
export const Message=styled.p`
    font-weight:600;
    font-size:20px;
    text-align:center;
    margin-top:4%;
`
export const Details=styled.div`
    display:flex;
    justify-content:space-between;
    margin:5% 0;
    width:100%;
    p{
        font-size:14px;
        color:rgba(132, 133, 142, 1);
        font-weight:500;
        &.amount{
            color:black;
        }
    }
`
export const DetailsBox=styled.div`
    width:100%;
    margin:10% 0;
`
export const Back=styled.div`
    background-color:rgba(38, 104, 178, 1);
    width:43%;
    margin:auto;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:3% 4%;
    border-radius: 3px;
    cursor: pointer;

    p{
        color:white;
        font-size:16px;
        margin:0 1%;
    }
`