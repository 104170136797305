import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ThemeProvider } from 'styled-components'

import { GlobalStyle } from './globalStyles'

import Content from './ui'

import { AuthProvider } from './context/auth'
import theme from './theme'

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)


function App() {
  return <AuthProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Elements stripe={stripePromise}>
        <Content />
      </Elements>
    </ThemeProvider>
  </AuthProvider>
}

export default App;


