import styled from 'styled-components'

export const Container = styled.div`
    width: 1216px;
    padding-bottom: 1rem;
    background-color: ${props => props.theme.colors.white};
    margin: 0 auto;
    margin-top: 17px;
`;

export const Head = styled.div`
    width: 100%;
    height: 50px;
    border-bottom: 1px solid ${props => props.theme.colors.borderGrey};

    display: flex;
    align-items: center;

    h2 {
        margin-left: 50px;
        font-weight: 600;
    }
`;

export const CurrencySelection = styled.div`
    width: 1116px;
    height: 70px;
    margin: 0 auto;
    margin-top: 33px;
    background-color: ${props => props.theme.colors.lightBlue};
    border-radius: 3px;

    display: flex;
    justify-content: space-between;
`;

export const Instruction = styled.div`
    width: 500px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
        font-weight: 400;
    }
`;

export const CurrencyButtons = styled.div`
    width: 164px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const INRBtn = styled.button`
    width: 56px;
    height: 41px;
    border: none;
    border-radius: 3px 0px 0px 3px;
    cursor: pointer;

    background-color: ${props => props.currency === 'inr' ?  props.theme.colors.main : 'Transparent'};
    color: ${props => props.currency === 'inr' ?  'white' : 'none'};

    border-top: 1px solid ${props => props.currency === 'inr' ?  'none' : props.theme.colors.buttonBorder };
    border-left: 1px solid ${props => props.currency === 'inr' ?  'none' : props.theme.colors.buttonBorder };
    border-bottom: 1px solid ${props => props.currency === 'inr' ?  'none' : props.theme.colors.buttonBorder };
`;

export const USDBtn = styled.button`
    width: 56px;
    height: 41px;
    border: none;
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;

    background-color: ${props => props.currency === 'usd' ?  props.theme.colors.main : 'Transparent'};
    color: ${props => props.currency === 'usd' ?  'white' : 'none'};

    border-top: 1px solid ${props => props.currency === 'usd' ?  'none' : props.theme.colors.buttonBorder };
    border-right: 1px solid ${props => props.currency === 'usd' ?  'none' : props.theme.colors.buttonBorder };
    border-bottom: 1px solid ${props => props.currency === 'usd' ?  'none' : props.theme.colors.buttonBorder };
`;

export const KeyFeatures = styled.table`
    width: 1116px;
    margin: 0 auto; 
    border-collapse: collapse;
        
    th,
    td {
        padding: 16px 24px;
        text-align: left;

        display: flex;
        align-items: center;

        img {
            margin-right: 27px;
        }
    }

    tbody td {
        font-weight: 400;
    }

    thead th {
        background-color:  ${props => props.theme.colors.tableHeading};
        height: 51px;
        font-weight: 500;
    }

    tbody tr {
        border-bottom: 1px solid ${props => props.theme.colors.borderGrey};
        border-right: 1px solid ${props => props.theme.colors.borderGrey};
        border-left: 1px solid ${props => props.theme.colors.borderGrey};

        display: flex;
        align-items: center;
        height: 50px;
    }
    tbody tr:nth-child(even) {
        /* background-color: #f8f9fa; */
    }

    tbody tr:nth-child(odd) {
        /* background-color: #e9ecef; */
    }
`;

export const Ruler = styled.div`
    border: 1px solid ${props => props.theme.colors.borderGrey};
    margin: 34px 0;
`;