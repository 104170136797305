import React, { useState, createContext, useContext } from 'react'
import _static from '../../../../config/static'
import axios from 'axios'

const TableContext = createContext(null)
const { baseUrl } = _static

export const TableProvider = ({ children }) => {
    const [userLeads, setUserLeads] = useState([])

    const getUserLeads = async () => {
        try {
          const headers = {
            "Content-Type": 'application/json'
          }
  
          const response = await axios(`${baseUrl}/leadspyder/api/lead/all`, {
              method: 'GET',
              headers,
              withCredentials: true
          })
  
          setUserLeads(response.data)
        } catch(err) {
            console.log('err', err)
        }
      }

    return <TableContext.Provider value={{ getUserLeads, userLeads }}>
        {children}
    </TableContext.Provider>
}

export const useCustomTable = () => {
    return useContext(TableContext)
}